<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="AR Payment Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Operating Unit / Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="operatingUnit"
                  :options="optionOU"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  track-by="ID"
                  label="Name"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.Name }}</span>
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title">{{
                        props.option.SelectName
                      }}</span>
                    </div>
                  </template>
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <date-range-picker
                  style="min-height: 40px"
                  class="w-full"
                  ref="picker"
                  opens="center"
                  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="filteDate"
                  :linkedCalendars="true"
                >
                  <!-- <template v-slot:input="picker" style="min-width: 350px"> -->
                  <template v-slot:input="picker">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div v-bind:class="[detail ? detailShow : '', detailHide]">
                <div class="vx-col sm:w-1/1 w-full mb-base">
                  <data-table
                    :baseUrl="this.baseUrl"
                    :detail="this.detail"
                    :startDate="this.filteDate.startDate"
                    :endDate="this.filteDate.endDate"
                    :territoryIDs="this.territoryIDs"
                    :territoryNames="this.territoryNames"
                    :draw="draw"
                  ></data-table>
                </div>
              </div>
              <transition name="detail-fade">
                <div
                  v-if="detail"
                  v-bind:class="[detail ? detailShow : '', detailHide]"
                >
                  <vs-row>
                    <vs-col
                      style="padding-bottom: 8px"
                      vs-offset="8"
                      vs-type="flex"
                      vs-justify="rigth"
                      vs-align="rigth"
                      vs-w="4"
                    >
                      <vs-button
                        class="ml-auto"
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                        >Close</vs-button
                      >
                    </vs-col>
                    <vs-col
                      class="vertical-divider"
                      vs-offset="0"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="1"
                    >
                      <vs-button
                        size="small"
                        v-on:click="handleClose"
                        color="grey"
                        icon-pack="feather"
                        icon="icon-x-square"
                      ></vs-button>
                    </vs-col>
                    <!-- <FormPlan @close="handleClose" :baseUrl="this.baseUrl"></FormPlan> -->
                  </vs-row>
                </div>
              </transition>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DataTable from "./DataTable.vue";
import DataTableExport from "./DataTableExport.vue";
import moment from "moment";
export default {
  components: {
    DataTable,
    DataTableExport,
    DateRangePicker
  },
  data() {
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,

      baseUrl: "/api/v1/report/payment",

      filteDate: {
        startDate: null,
        endDate: null
      },
      operatingUnit: [],
      optionOU: [
        {
          OperatingUnitTerritory: {
            Territory: {
              code: ""
            }
          }
        }
      ],
      draw: 0,
      territoryIDs: [],
      territoryNames: [],
      defaultOptionOU: {
        ID: 0,
        CreatedAt: "",
        DeletedAt: null,
        CompanyID: 0,
        TaxID: 0,
        SppkpID: 0,
        CostCenterID: 0,
        IsHeadOffice: false,
        Name: "All",
        Description: "",
        Code: "All",
        ExternalCode: "",
        ErpMapping: "",
        ExternalMapping: "",
        Npwp: "",
        Sppkp: "",
        TaxAddress: "",
        OperatingUnitTerritory: {
          ID: 0,
          CreatedAt: null,
          DeletedAt: null,
          OperatingUnitID: 0,
          TerritoryID: 0,
          Territory: {
            ID: 0,
            CreatedAt: null,
            DeletedAt: null,
            company_id: 0,
            territory_area_id: 0,
            code: "All",
            name: "All",
            description: "-",
            territory_geo: {
              ID: 0,
              CreatedAt: null,
              DeletedAt: null,
              territory_id: 0,
              city: "",
              district: "",
              sub_district: "",
              country: "",
              province: "",
              address: "",
              postal_code: ""
            },
            external_code: ""
          }
        }
      }
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("MMM DD, YYYY");
      }
      return a;
    },
    getOptionOU() {
      this.$http.get("/api/v1/master/operating-unit-territory").then(resp => {
        this.optionOU = [];
        let data = resp.data.records;
        for (var idx = 0; idx < data.length; idx++) {
          this.optionOU.push({
            CodeOu: data[idx].Territory.code,
            CodeOuTerr: data[idx].Territory.code,
            CodeTerr: data[idx].Territory.code,
            ID: data[idx].Territory.ID,
            IDTerr: data[idx].Territory.ID,
            code: data[idx].Territory.Code,
            SelectName:
              " (" + data[idx].Territory.code + ") " + data[idx].Territory.name,
            Name:
              " (" + data[idx].Territory.code + ") " + data[idx].Territory.name
          });
        }
        this.operatingUnit = [this.optionOU[0]];
        // console.log(this.optionOU);

        // this.operatingUnit = resp.data.records[0];
        // this.operatingUnit.push(resp.data.records[0]);
      });
    },
    handleDrawTable() {
      this.draw++;
    }
  },
  mounted() {
    this.getOptionOU();
    const today = new Date();
    this.filteDate.startDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    this.filteDate.endDate = new Date(today.getFullYear(), today.getMonth(), 0);
  },
  watch: {
    operatingUnit(val) {
      if (val.length == 0) {
        val.push(this.defaultOptionOU);
      } else if (val.length > 1) {
        if (val[0].ID == 0) {
          val.splice(0, 1);
        }
      }

      let territoryIDs = [];
      let territoryNames = [];

      val.forEach(e => {
        if (e.ID != 0) {
          territoryIDs.push(e.ID);
          territoryNames.push(e.Name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryNames = territoryNames;
    }
  }
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
